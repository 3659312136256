import React from 'react'
import './Header.css'

function Header() {
  return (
    <div className='main_container flt'>
        <div className="header_containers flt">
            <div className="container">
                <div className="logo flt">
                    <img src="./logo.png" alt="icon" />
                </div>
            </div>
        </div>
        <div className="menu_containers flt">
            <div className="container">
                <ul className="menuH flt">
                    <li><a href="https://summer.manipal.edu/">Home</a></li>
                    <li><a href="#">Courses <i className="fas fa-chevron-down"></i></a>
                    <ul className="dropdown">
                        <li><a href="#">Ayursamskrithi – the Science and Art of Living Through Ayurveda</a></li>
                        <li><a href="#">Dental Public Health</a></li>
                        <li><a href="#">Wilderness Medicine</a></li>
                        <li><a href="#">Tropical Ecosystems – The western Ghats Biodiversity hotspot</a></li>
                        <li><a href="#">Glimpses of Indian Cuisine</a></li>
                        <li><a href="#">Indian Cinema</a></li>
                    </ul>
                    </li>
                    <li><a href="https://summer.manipal.edu/index.php/about-us/">About Us</a></li>
                    <li><a href="https://summer.manipal.edu/index.php/contact-us/">Contact Us</a></li>
                </ul>
            </div>
        </div>
        <div className="container">
                <div className="banner_container flt">
                    MAHE International Summer School (MISS)-2024
                </div>
                {/* <div className="page_content flt">

                </div> */}
            </div>
    </div>
  )
}

export default Header
