import * as axios from "../network/Network";

const config = require("../network/api.json");

class UserService {
  static login(userName, password) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.login,
      data: { userName, password },
    });
  }
}
export { UserService };
